import { useEffect, useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { thumbnailPlugin, RenderThumbnailItemProps } from "@react-pdf-viewer/thumbnail";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { fullScreenPlugin } from "@react-pdf-viewer/full-screen";
import CheckStatusMessage from "./CheckStatusMessage";
import { isAddressProvided } from "@/utils";

// Import CSS styles for the plugins
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/full-screen/lib/styles/index.css";

import {
  CancelRounded,
  CheckCircleRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  ZoomOutRounded,
  ZoomInRounded,
  FullscreenRounded,
  LaunchRounded,
  ErrorRounded,
  MailRounded,
  EmailRounded,
} from "@mui/icons-material";
import AddressValue from "./AddressValue";
import WrappedValue from "./WrappedValue";
import InfoValue from "./InfoValue";
import Skeleton from "./Skeleton";
import { useParchaApi } from "@/hooks/useParchaApi";
import {
  BUSINESS_OWNERSHIP_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  SOURCE_OF_FUNDS_CHECK_ID,
  KYC_PROOF_OF_ADDRESS_CHECK_ID,
  shimmerEffectClassNames,
} from "@/constants/vars";
import { format } from "date-fns";
import { twJoin } from "tailwind-merge";
import FullWidthTabs from "./FullWidthTabs";
const DocumentValidation = ({
  agentInstanceId,
  agentType,
  checkId,
  checkName,
  documentName,
  documentUrl,
  endpointUrl,
  jobId,
}: any) => {
  const [checkResult, setCheckResult] = useState<any>(null);
  const [showAnswer, setShowAnswer] = useState<boolean>(false);
  const [pdfError, setPdfError] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("found-information");
  const parchaApi = useParchaApi();

  const thumbnailPluginInstance = thumbnailPlugin();
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const fullScreenPluginInstance = fullScreenPlugin();

  const { ZoomIn, ZoomOut } = zoomPluginInstance;
  const { Thumbnails } = thumbnailPluginInstance;
  const { CurrentPageLabel, NumberOfPages } = pageNavigationPluginInstance;
  const { EnterFullScreen } = fullScreenPluginInstance;

  useEffect(() => {
    parchaApi
      .getCheckResultFromJob(endpointUrl, jobId, checkId, agentInstanceId, agentType, true)
      .then((checkResult) => {
        setCheckResult(checkResult);
        if (checkResult.passed !== null && !checkResult.passed) {
          setShowAnswer(true);
        }
      });
    const interval = setInterval(() => {
      parchaApi
        .getCheckResultFromJob(endpointUrl, jobId, checkId, agentInstanceId, agentType, true)
        .then((checkResult) => {
          setCheckResult(checkResult);

          if (checkResult.passed !== null && !checkResult.passed) {
            setShowAnswer(true);
          }

          if (["error", "complete"].includes(checkResult.status)) {
            clearInterval(interval);
          }
        });
    }, 2000);

    return () => clearInterval(interval);
  }, [jobId, agentInstanceId, checkId]);

  if (!checkResult) return null;

  const renderThumbnailItem = (props: RenderThumbnailItemProps) => {
    const { pageIndex, currentPage, onJumpToPage, key } = props;
    return (
      <div
        key={key}
        className={`w-fit h-fit mb-4 ${
          currentPage === pageIndex ? "border-2 border-solid border-brand-purple" : ""
        } hover:cursor-pointer`}
        onClick={onJumpToPage}
      >
        {props.renderPageThumbnail}
      </div>
    );
  };

  type Value = {
    value: any;
    label: string;
    type: string;
  };

  const getValues = () => {
    let values: Value[] = [];

    switch (checkId) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        const combinedIncorporationDocuments = [
          ...(checkResult.check_result?.verified_incorporation_documents || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];

        const incorporationDocument =
          combinedIncorporationDocuments.length > 0 ? combinedIncorporationDocuments[0] : null;

        values = [
          {
            value:
              checkResult.check_result?.verified_business_name ||
              incorporationDocument?.business_name ||
              incorporationDocument?.extraction_data?.business_name,
            label: "Business Name",
            type: "text",
          },
          {
            value: checkResult.check_result?.verified_incorporation_date
              ? format(new Date(checkResult.check_result.verified_incorporation_date), "MMMM dd, yyyy")
              : incorporationDocument?.incorporation_date || incorporationDocument?.extraction_data?.incorporation_date
                ? format(
                    new Date(
                      incorporationDocument.incorporation_date ||
                        incorporationDocument.extraction_data.incorporation_date,
                    ),
                    "MMMM dd, yyyy",
                  )
                : null,
            label: "Incorporation Date",
            type: "text",
          },
          {
            value: checkResult.check_result?.verified_registration_number
              ? checkResult.check_result?.verified_registration_number
              : incorporationDocument?.business_registration_number
                ? incorporationDocument?.business_registration_number?.join(", ")
                : incorporationDocument?.extraction_data?.business_registration_number
                  ? incorporationDocument.extraction_data.business_registration_number.join(", ")
                  : null,
            label: "Registration Number",
            type: checkResult.check_result?.verified_registration_number ? "wrapped" : "text",
          },
          {
            value: isAddressProvided(checkResult.check_result?.verified_business_address)
              ? checkResult.check_result?.verified_business_address
              : isAddressProvided(incorporationDocument?.business_address)
                ? incorporationDocument?.business_address
                : isAddressProvided(incorporationDocument?.extraction_data?.business_address)
                  ? incorporationDocument?.extraction_data?.business_address
                  : null,
            label: "Business Address",
            type: "address",
          },
          {
            value:
              checkResult.check_result?.verified_business_activity ||
              incorporationDocument?.business_activity ||
              incorporationDocument?.extraction_data?.business_activity,
            label: "Business Activity",
            type: "text",
          },
        ];
        if (incorporationDocument) {
          values.push({
            value: incorporationDocument.summary || incorporationDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });

          if (incorporationDocument.fraud_verification_data) {
            values.push({
              value: incorporationDocument.fraud_verification_data.verification_description,
              label: "Anti-Tampering and Fraud Verification",
              type: "text",
            });
          }

          if (incorporationDocument.visual_inspection) {
            values.push({
              value: {
                validStateSeal: incorporationDocument.visual_inspection.seal_valid,
                signature: incorporationDocument.visual_inspection.signature_valid,
              },
              label: "Visual Inspection Results",
              type: "doc_visual_inspection_result",
            });
          }
        }
        break;
      case BUSINESS_OWNERSHIP_CHECK_ID:
        values = [
          {
            value: checkResult.check_result?.verified_business_owners,
            label: "Verified Business Owners",
            type: "owner_info",
          },
        ];

        if (checkResult.check_result?.unverified_business_owners) {
          values.push({
            value: checkResult.check_result?.unverified_business_owners,
            label: "Unverified Business Owners",
            type: "owner_info",
          });
        }

        const combinedOwnershipDocuments = [
          // ...(checkResult.verified_data?.documented_ownership || []),
          // ...(checkResult.verified_data?.invalid_documents || []),
          ...(checkResult.check_result?.documented_ownership || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];

        const businessOwnershipDocument = combinedOwnershipDocuments.length > 0 ? combinedOwnershipDocuments[0] : null;
        if (businessOwnershipDocument) {
          values.push({
            value: businessOwnershipDocument.summary || businessOwnershipDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });
        }
        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        const combinedProofOfAddressDocuments = checkResult.verified_data
          ? [
              // ...(checkResult.verified_data?.proof_of_address_documents || []),
              // ...(checkResult.verified_data?.invalid_documents || []),
              ...(checkResult.check_result?.proof_of_address_documents || []),
              ...(checkResult.check_result?.valid_documents || []),
              ...(checkResult.check_result?.invalid_documents || []),
            ]
          : [];

        const proofOfAddressDocument =
          combinedProofOfAddressDocuments.length > 0 ? combinedProofOfAddressDocuments[0] : null;

        values.push({
          value: isAddressProvided(checkResult.check_result?.verified_address)
            ? checkResult.check_result?.verified_address
            : proofOfAddressDocument?.address,
          label: "Business Address",
          type: "address",
        });

        if (proofOfAddressDocument) {
          values.push({
            value: proofOfAddressDocument.summary || proofOfAddressDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });
        }
        break;
      case EIN_DOCUMENT_CHECK_ID:
        const combinedEINDocuments = [
          // ...(checkResult.verified_data?.valid_ein_documents || []),
          // ...(checkResult.verified_data?.invalid_documents || []),
          ...(checkResult.check_result?.valid_ein_documents || []),
          ...(checkResult.check_result?.valid_documents || []),
          ...(checkResult.check_result?.invalid_documents || []),
        ];
        const einDocument = combinedEINDocuments.length > 0 ? combinedEINDocuments[0] : null;

        values.push({
          value: checkResult.verified_business_name || einDocument?.business_name,
          label: "Business Name",
          type: "text",
        });
        values.push({
          value: checkResult.verified_ein || einDocument?.ein,
          label: "EIN",
          type: "wrapped",
        });

        if (einDocument) {
          values.push({
            value: einDocument.summary || einDocument.extraction_data.summary,
            label: "Summary",
            type: "text",
          });
        }
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        values.push({
          value: checkResult.verified_amount || "Not verified",
          label: "Amount",
          type: "text",
        });
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        const combinedKycProofOfAddressDocuments = [...(checkResult.check_result?.invalid_documents || [])];
        const kycProofOfAddressDocument =
          combinedKycProofOfAddressDocuments.length > 0 ? combinedKycProofOfAddressDocuments[0] : null;
        values.push({
          value: isAddressProvided(checkResult.check_result?.verified_address)
            ? checkResult.check_result?.verified_address
            : kycProofOfAddressDocument?.address,
          label: "Individual Address",
          type: "address",
        });
        break;
      default:
        break;
    }

    return values;
  };

  const getProvidedInformation = () => {
    let values: Value[] = [];

    const inputData = checkResult.inputs;

    if (!inputData) return [];

    switch (checkId) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        values = [
          {
            value: inputData.registered_business_name,
            label: "Business Name",
            type: "text",
          },
          {
            value: inputData.incorporation_date
              ? format(new Date(inputData.incorporation_date), "MMMM dd, yyyy")
              : null,
            label: "Incorporation Date",
            type: "text",
          },
          {
            value: inputData.business_registration_number,
            label: "Registration Number",
            type: "wrapped",
          },
          {
            value: inputData.incorporation_address,
            label: "Business Address",
            type: "address",
          },
        ];

        break;
      case PROOF_OF_ADDRESS_CHECK_ID:
        values = [
          {
            value: inputData.address,
            label: "Business Address",
            type: "address",
          },
        ];
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        values.push({
          value: inputData.address,
          label: "Individual Address",
          type: "address",
        });
        break;
      case EIN_DOCUMENT_CHECK_ID:
        values.push({
          value: inputData.business_name,
          label: "Business Name",
          type: "text",
        });
        values.push({
          value: inputData.ein,
          label: "EIN",
          type: "wrapped",
        });
        break;
      case SOURCE_OF_FUNDS_CHECK_ID:
        values.push({
          value: checkResult.verified_amount || "Not verified",
          label: "Amount",
          type: "text",
        });
        break;
      case KYC_PROOF_OF_ADDRESS_CHECK_ID:
        values.push({
          value: checkResult.verified_address,
          label: "Address",
          type: "address",
        });
        break;
      default:
        break;
    }
    return values;
  };

  const handleContactSupportClick = () => {
    const subject = `Document Validation Failure - ${checkResult.checkName}`;
    const body = `I'm having trouble with the ${checkResult.checkName} check for my ${checkResult.documentType} document. The job URL is ${checkResult.jobUrl} and the file URL is ${checkResult.fileUrl}.`;

    window.open(`mailto:support@parcha.com?subject=${subject}&body=${body}`, "_blank");
  };

  const onContactSupportClickHandler = () => {
    const subject = `Error in job ${jobId} for the ${checkName?.trim()} check`;
    const body = `There was an error in job with ID ${jobId} for the ${checkName?.trim()} check.`;
    const mailtoLink = `mailto:support@parcha.ai?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(
      body || "",
    )}`;
    window.open(mailtoLink, "_blank");
  };

  const statusEvents = checkResult?.status_messages?.filter((message: any) => message.event === "status");
  const lastStatusMessage = statusEvents && statusEvents[statusEvents.length - 1];

  const isPdf = documentName?.endsWith(".pdf");

  return (
    <div className="flex h-full text-xs">
      <div className="w-1/4 h-full  border-r bg-white overflow-y-auto">
        {checkResult.passed !== null ? (
          <div
            className={`text-base flex flex-col gap-y-2 px-4 py-4 ${
              checkResult.passed ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
            }`}
          >
            <div
              className={`flex items-center gap-x-2 ${
                checkResult.passed ? "bg-green-100 text-green-700" : "bg-red-100 text-red-700"
              }`}
            >
              <button onClick={() => setShowAnswer(!showAnswer)} className="inline-flex items-center">
                {showAnswer ? (
                  <ExpandLessRounded sx={{ fontSize: "1.125rem" }} />
                ) : (
                  <ExpandMoreRounded sx={{ fontSize: "1.125rem" }} />
                )}
              </button>
              {checkResult.passed ? (
                <CheckCircleRounded sx={{ fontSize: "1rem" }} />
              ) : (
                <CancelRounded sx={{ fontSize: "1rem" }} />
              )}
              <span className="font-semibold">{checkResult.passed ? "Pass" : "Fail"}</span>
            </div>
            {showAnswer && <p className="text-xs">{checkResult.answer}</p>}
          </div>
        ) : lastStatusMessage ? (
          <div className="p-4">
            <CheckStatusMessage messageContents={{ status: lastStatusMessage.content.status }} />
          </div>
        ) : null}
        <div className="p-4 mb-4">
          <div className="mb-4 flex flex-wrap items-center justify-between gap-x-4">
            <span className="text-base font-semibold">{checkName}</span>
            {/* <span className="text-xs">{checkResult.date}</span> */}
          </div>
          <FullWidthTabs
            tabs={[
              {
                name: "Found Information",
                id: "found-information",
                count: 0,
              },
              {
                name: "Provided Information",
                id: "provided-information",
                count: 0,
              },
            ]}
            selectedTab={selectedTab}
            onTabChangeHandler={(tabId: string) => setSelectedTab(tabId)}
            tabLabelClassName="text-[0.875rem] font-normal"
          />
          <div className="space-y-4 pb-12 pt-4">
            {selectedTab === "found-information" ? (
              checkResult.status === "error" ? (
                <div className=" flex flex-col justify-center items-center w-full p-2 md:p-5 lg:p-8 text-sm gap-y-2 bg-slate-50 rounded-[1.25rem]">
                  <ErrorRounded sx={{ fontSize: "2rem" }} className="text-red-600" />
                  <div className="text-center">
                    <p className="text-xs lg:text-sm font-semibold lg:font-bold text-slate-700">
                      An error has occurred
                    </p>
                    <p className="text-slate-500 text-xs leading-5">We apologize for the inconvenience.</p>
                    <p className="text-slate-500 text-xs leading-5">
                      Our team has been notified and will look into it as soon as possible.
                    </p>
                    <p className="mt-4 text-xs text-slate-500">If the problem persists please contact support.</p>
                    <button
                      type="button"
                      className="mt-4 inline-flex items-center gap-x-2 text-slate-900 px-3 py-1.5 rounded-md bg-white border border-solid border-slate-300 shadow-sm"
                      onClick={onContactSupportClickHandler}
                    >
                      <EmailRounded sx={{ fontSize: "1rem" }} className="mr-1 h-4 w-4" aria-hidden="true" />
                      <span className="line-clamp-1 text-xs">Contact Support</span>
                    </button>
                  </div>
                </div>
              ) : (
                getValues().map((value: any, index: any) => (
                  <ValueContainer
                    key={index}
                    value={value.value}
                    label={value.label}
                    type={value.type}
                    checkStatus={checkResult.status}
                    level="normal"
                  />
                ))
              )
            ) : null}

            {selectedTab === "provided-information" &&
              getProvidedInformation().map((value: any, index: any) => {
                return (
                  <ValueContainer
                    key={index}
                    value={value.value}
                    label={value.label}
                    type={value.type}
                    level="normal"
                    checkStatus={checkResult.status}
                    isProvidedInformation
                  />
                );
              })}
          </div>
        </div>
      </div>
      <div className="h-full flex flex-1 mb-4">
        <div className="flex flex-col h-[calc(100%-3.5rem)] w-full gap-y-4 w-3/4 rounded-md">
          {isPdf && (
            <div className="h-[90%]">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer
                  transformGetDocumentParams={(options) => ({ ...options, isEvalSupported: false })}
                  plugins={[
                    thumbnailPluginInstance,
                    zoomPluginInstance,
                    pageNavigationPluginInstance,
                    fullScreenPluginInstance,
                  ]}
                  fileUrl={documentUrl}
                  onDocumentLoad={() => setPdfError(false)}
                  renderError={() => {
                    setPdfError(true);
                    return (
                      <div className="flex items-center justify-center h-full">
                        <div className="flex flex-col gap-y-5 p-10 items-center bg-slate-100 rounded-lg max-w-[25rem]">
                          <ErrorRounded sx={{ fontSize: "2.25rem" }} className="text-red-600" />
                          <div className="flex flex-col justify-center items-center text-slate-700">
                            <h3 className="text-sm font-semibold">We were unable to load the document</h3>
                            <h4 className="text-xs">We apologize for the inconvenience.</h4>
                          </div>
                          <p className="text-xs text-center text-slate-500">
                            If the problem persists, please contact support and we will look into the issue as soon as
                            possible.
                          </p>
                          <button
                            onClick={handleContactSupportClick}
                            className="inline-flex items-center gap-x-2 text-slate-900 px-3 py-1.5 rounded-md bg-white border border-solid border-slate-300 shadow-sm"
                          >
                            <MailRounded sx={{ fontSize: "1rem" }} />
                            <span>Contact Support</span>
                          </button>
                        </div>
                      </div>
                    );
                  }}
                />
              </Worker>
            </div>
          )}
          {!isPdf && <img src={documentUrl} alt={documentName} className="h-full w-full object-contain" />}
          {isPdf && !pdfError && (
            <div className="flex h-[10%] p-4 items-center justify-center gap-x-2 border-t border-solid border-slate-200">
              <ZoomOut>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <ZoomOutRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </ZoomOut>{" "}
              <ZoomIn>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <ZoomInRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </ZoomIn>
              <div className="flex items-center gap-x-1">
                Page <CurrentPageLabel /> of <NumberOfPages />
              </div>
              <EnterFullScreen>
                {(props) => (
                  <button
                    {...props}
                    className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
                  >
                    <FullscreenRounded sx={{ fontSize: "1.125rem" }} />
                  </button>
                )}
              </EnterFullScreen>
              <a
                href={documentUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="border border-solid border-slate-300 rounded-md text-slate-900 px-1 py-0.5"
              >
                <LaunchRounded sx={{ fontSize: "1.125rem" }} />
              </a>
            </div>
          )}
        </div>
        {isPdf && !pdfError && (
          <div className="h-full w-1/4 bg-slate-100 border-l border-solid border-slate-200">
            <Thumbnails renderThumbnailItem={renderThumbnailItem} />
          </div>
        )}
      </div>
    </div>
  );
};

const ValueContainer = ({ value, label, type, level, checkStatus, isProvidedInformation }: any) => {
  const getValue = () => {
    if (!value && !["complete", "error"].includes(checkStatus) && !isProvidedInformation) {
      return <Skeleton width="w-full" height="h-4" shape="rounded" />;
    }

    if (type === "address") {
      return value &&
        isAddressProvided({
          street1: value.street_1,
          street2: value.street_2,
          city: value.city,
          state: value.state,
          countryCode: value.country_code,
          postalCode: value.postal_code,
        }) ? (
        <AddressValue
          street1={value.street_1}
          street2={value.street_2}
          city={value.city}
          state={value.state}
          countryCode={value.country_code}
        />
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    } else if (type === "wrapped") {
      return value?.length > 0 ? (
        <WrappedValue text={value} level={level} />
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    } else if (type === "owner_info") {
      return value?.map((owner: any) => (
        <div key={owner.full_name} className="flex flex-col">
          <span className="uppercase">{owner.full_name}</span>
          {owner.percentage === null ? (
            <InfoValue label="Percent Ownership" value="Unknown" />
          ) : typeof owner.percentage === "number" ? (
            <InfoValue
              label="Percent Ownership"
              value={`${
                owner.percentage >= 0 && owner.percentage <= 1
                  ? (owner.percentage * 100).toFixed(0)
                  : owner.percentage % 1 === 0
                    ? Math.round(owner.percentage)
                    : owner.percentage.toFixed(2)
              }%`}
            />
          ) : null}
          {owner.shares === null ? (
            <InfoValue label="Shares" value="Unknown" />
          ) : (
            typeof owner.shares === "number" && <InfoValue label="Shares" value={`${owner.shares.toLocaleString()}`} />
          )}
        </div>
      ));
    } else if (type === "doc_visual_inspection_result") {
      return (
        <div className="flex flex-col gap-2">
          {value.validStateSeal !== null && (
            <InfoValue label=" Valid State Seal" value={value.validStateSeal ? "Yes" : "No"} />
          )}
          {value.signature !== null && (
            <InfoValue label=" Secretary Signature" value={value.signature ? "Yes" : "No"} />
          )}
        </div>
      );
    } else {
      return value ? (
        <p>{value}</p>
      ) : isProvidedInformation ? (
        <span className="text-slate-400 italic">Not provided</span>
      ) : null;
    }
  };

  return (
    <div
      className={twJoin(
        "p-4 text-slate-700 rounded-md flex flex-col gap-y-2",
        isProvidedInformation
          ? "bg-white border border-solid border-transparent"
          : "bg-slate-50 border border-solid border-slate-50",
      )}
    >
      <h4
        className={twJoin(
          "font-semibold",
          checkStatus !== "complete" && !isProvidedInformation ? shimmerEffectClassNames : "",
        )}
      >
        {label}
      </h4>
      {getValue() || <span className="text-slate-400 italic">{`No ${label} found`}</span>}
    </div>
  );
};

export default DocumentValidation;
