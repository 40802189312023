// @ts-nocheck
import { twMerge } from "tailwind-merge";
import { useState, useEffect } from "react";
import { useParchaApi } from "@/hooks/useParchaApi";
import Icon from "@mui/material/Icon";
import { CancelRounded, ContentCopyRounded, DescriptionRounded, LinkRounded } from "@mui/icons-material";
import { Endpoint } from "@/types";
import { format } from "date-fns";
import {
  EIN_DOCUMENT_CHECK_ID,
  INCORPORATION_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  CHECK_ID_TO_TITLE,
  CHECK_ID_TO_ICON,
} from "@/constants/vars";

const DocumentRequestInfo = ({ endpoint, requestId }: { endpoint: Endpoint | null; requestId: string }) => {
  const [requestInfo, setRequestInfo] = useState();
  const parchaApi = useParchaApi();

  useEffect(() => {
    if (!endpoint) return;

    parchaApi.getDocumentRequest(endpoint.endpointUrl, requestId).then((res) => {
      setRequestInfo(res);
    });
  }, [requestId]);

  if (!requestInfo) return null;

  const getAddressText = (address: any) => {
    const addressText = `${address.street_1 ? address.street_1 : ""} ${address.street_2 ? address.street_2 : ""} ${
      address.city ? address.city : ""
    } ${address.state ? address.state : ""} ${address.zip_code ? address.zip_code : ""}`;
    return addressText.trim();
  };

  const modalTextContent =
    `<div className="flex flex-col gap-3 p-3 h-full justify-between border border-slate-200 rounded-lg text-sm text-slate-700">
    <p>
      We require a copy of your official business registration document to proceed with your application. Please
      provide a clear, legible copy of the document that shows your business name, registration number, and
      jurisdiction.
    </p>
    <div className="text-center w-full">
    
      <a
        href="${window.location.origin}/submit-doc?request_id=` +
    requestId +
    `"
        className="text-brand-purple font-semibold"
        target="_blank"
      >
        Securely submit your document here »
      </a>
    </div>
    <p>If you have any questions or need further clarification, please don't hesitate to contact us.</p>
    <p>Thank you for your prompt attention to this matter.</p>
    <span>
      Regards,
      <br />
      <span>FastBank</span>
    </span>
  </div>`;

  const handleCopyRequestLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/submit-doc?request_id=${requestId}`);
  };

  const handleCopyEmailText = () => {
    navigator.clipboard.write([
      new ClipboardItem({
        ["text/html"]: new Blob([modalTextContent], { type: "text/html" }),
      }),
    ]);
  };

  const renderFields = () => {
    switch (requestInfo.document_request.check_id) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return (
          <div className="grid grid-cols-4 gap-2">
            <Value
              classNames="col-span-3"
              title="Registered Business Name"
              value={requestInfo.case.registered_business_name || "Not provided"}
            />
            <Value
              title="Registration Number"
              value={requestInfo.case.business_registration_number || "Not provided"}
            />
            <Value
              classNames="col-span-3"
              title="Address of Incorporation"
              value={
                getAddressText(
                  requestInfo.case.addresses.find((address) => address.address_type === "incorporation"),
                ) || "Not provided"
              }
            />
            <Value title="Date of Incorporation" value={requestInfo.case.incorporation_date || "Not provided"} />
          </div>
        );
      case EIN_DOCUMENT_CHECK_ID:
        return (
          <div className="grid grid-cols-4 gap-2">
            <Value
              classNames="col-span-2"
              title="Registered Business Name"
              value={requestInfo.case.registered_business_name || "Not provided"}
            />
            <Value
              classNames="col-span-2"
              title="Employer Identification Number (EIN)"
              value={requestInfo.case.tin_number || "Not provided"}
            />
          </div>
        );
      case PROOF_OF_ADDRESS_CHECK_ID:
        return (
          <div className="grid grid-cols-4 gap-2">
            <Value
              classNames="col-span-2"
              title="Business Name"
              value={requestInfo.case.business_name || "Not provided"}
            />
            <Value
              classNames="col-span-2"
              title="Address"
              value={
                getAddressText(requestInfo.case.addresses.find((address) => address.address_type === "operations")) ||
                "Not provided"
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full flex flex-col gap-y-10 max-w-3xl mx-auto py-8">
      <div className="flex gap-2 justify-between items-center">
        <h2 className="flex items-centergap-1 font-semibold">
          <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
            {CHECK_ID_TO_ICON[requestInfo.document_request.check_id]}
          </Icon>
          <p className="text-slate-900 font-semibold">{`${
            CHECK_ID_TO_TITLE[requestInfo.document_request.check_id]
          } Document Request`}</p>
        </h2>
        {requestInfo && (
          <div className="flex gap-1 text-xs">
            <span className="font-semibold">{`Request Created ${format(
              new Date(`${requestInfo?.document_request.created_at}Z`),
              "MMM dd",
            )}`}</span>
            <span>{` at ${format(new Date(`${requestInfo.document_request.created_at}Z`), "h:mm a")}`}</span>
          </div>
        )}
      </div>
      {renderFields()}
      <div className="flex flex-col gap-3 p-3 h-full justify-between border border-slate-200 rounded-lg text-sm text-slate-700">
        <p>
          We require a copy of your official business registration document to proceed with your application. Please
          provide a clear, legible copy of the document that shows your business name, registration number, and
          jurisdiction.
        </p>
        <div className="text-center w-full">
          <a
            href={`${window.location.origin}/submit-doc?request_id=${requestId}`}
            className="text-brand-purple font-semibold"
            target="_blank"
          >
            Securely submit your document here »
          </a>
        </div>
        <p>If you have any questions or need further clarification, please don't hesitate to contact us.</p>
        <p>Thank you for your prompt attention to this matter.</p>
        <span>
          Regards,
          <br />
          <span>FastBank</span>
        </span>
      </div>
      <div className="flex justify-end text-sm">
        {/* <button className="border border-slate-200 rounded px-4 py-2 text-red-700">
          <CancelRounded sx={{ fontSize: "1rem" }} className="mr-2" />
          Cancel Request
        </button> */}
        <div className="flex gap-2">
          <button className="border border-slate-200 rounded px-4 py-2" onClick={handleCopyRequestLink}>
            <LinkRounded sx={{ fontSize: "1rem" }} className="mr-2" />
            Copy Request Link
          </button>
          <button className="bg-brand-purple text-white rounded px-4 py-2" onClick={handleCopyEmailText}>
            <ContentCopyRounded sx={{ fontSize: "1rem" }} className="mr-2" />
            Copy Request Message
          </button>
        </div>
      </div>
    </div>
  );
};

export const Value = ({ title, value, classNames }: { title: string; value: string; classNames?: string }) => {
  return (
    <div className={twMerge("flex flex-col gap-y-2 bg-slate-50 rounded-lg p-3 text-xs text-slate-700", classNames)}>
      <p className="font-semibold">{title}</p>
      <p>{value}</p>
    </div>
  );
};

export default DocumentRequestInfo;
