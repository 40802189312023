// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import { OfflineBoltRounded } from "@mui/icons-material";
import { useSearchParams } from "react-router-dom";
import { useParchaApi } from "@/hooks/useParchaApi";
import { twJoin } from "tailwind-merge";
import { format } from "date-fns";
import DocumentField from "./DocumentField";
import UserContext from "@/contexts/UserContext";
import {
  INCORPORATION_DOCUMENT_CHECK_ID,
  EIN_DOCUMENT_CHECK_ID,
  PROOF_OF_ADDRESS_CHECK_ID,
  CHECK_ID_TO_TITLE,
  CHECK_ID_TO_ICON,
  DOCUMENT_REQUEST_STATUS_TO_CLASSNAMES,
} from "@/constants/vars";
import { Value } from "./DocumentRequestInfo";
import { isAddressProvided, snakeCaseToProperCase } from "@/utils";

const DocumentRequestSubmission = () => {
  const [latestApplicationState, setLatestApplicationState] = useState({});
  const [intervalId, setIntervalId] = useState(null);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [isApplicationSubmitted, setIsApplicationSubmitted] = useState(false);
  const [requestData, setRequestData] = useState(null);
  const [checkJobIds, setCheckJobIds] = useState({});

  const userContext = useContext(UserContext);
  const endpoints = userContext?.endpoints;
  const endpoint = requestData?.document_request?.agent_key
    ? endpoints?.find((e) => e.agentKey === requestData?.document_request?.agent_key)
    : null;

  const [searchParams] = useSearchParams();
  const requestId = searchParams.get("request_id");

  const parchaApi = useParchaApi();

  useEffect(() => {
    if (!requestId) return;

    const envTier = import.meta.env.VITE_ENV_TIER;
    const endpointUrl =
      envTier === "development" ? "localhost:8001" : envTier === "staging" ? "staging.parcha.ai" : "demo.parcha.ai";

    parchaApi.getDocumentRequest(endpointUrl, requestId).then((res) => {
      setRequestData(res);
    });
  }, [requestId]);

  const incorporationAddress = requestData?.case?.addresses.find((address) => address.address_type === "incorporation");
  const operationsAddress = requestData?.case?.addresses.find((address) => address.address_type === "operations");

  const runFileCheck = async (checkId: string, document) => {
    const checkRunResponse = await parchaApi.runCheck(endpoint?.endpointUrl, checkId, {
      agent_key: requestData?.document_request?.agent_key,
      kyb_schema: {
        id: requestData.document_request.applicant_id,
        self_attested_data: {
          business_name: requestData?.case?.business_name?.length > 0 ? requestData?.case?.business_name : undefined,
          registered_business_name:
            requestData?.case?.registered_business_name?.length > 0
              ? requestData?.case?.registered_business_name
              : undefined,
          proof_of_address_documents: checkId === PROOF_OF_ADDRESS_CHECK_ID ? [document] : undefined,
          incorporation_documents: checkId === INCORPORATION_DOCUMENT_CHECK_ID ? [document] : undefined,
          ein_documents: checkId === EIN_DOCUMENT_CHECK_ID ? [document] : undefined,
          address_of_operation: isAddressProvided(operationsAddress) ? operationsAddress : undefined,
          address_of_incorporation: isAddressProvided(incorporationAddress) ? incorporationAddress : undefined,
          incorporation_date: requestData?.case?.incorporation_date ? requestData?.case?.incorporation_date : undefined,
          tin_number: requestData?.case?.tin_number?.length > 0 ? requestData?.case?.tin_number : undefined,
          business_registration_number:
            requestData?.case?.business_registration_number?.length > 0
              ? requestData?.case?.business_registration_number
              : undefined,
        },
      },
      check_args: requestData?.document_request?.check_args || {},
    });

    setCheckJobIds({ ...checkJobIds, [checkId]: checkRunResponse?.id });
    return checkRunResponse;
  };

  useEffect(() => {
    if (!requestData?.document_request.applicant_id) return;

    const getApplicationState = async () => {
      const latestApplicationState = await parchaApi
        .getLatestApplicationState(
          endpoint?.endpointUrl,
          requestData?.document_request?.applicant_id,
          requestData?.document_request?.agent_key,
        )
        .catch((err) => ({ current_check_results: [], past_check_results: [] }));
      setLatestApplicationState(latestApplicationState);
    };

    getApplicationState();
  }, [requestData?.document_request.applicant_id, endpoint]);

  useEffect(() => {
    if (!checkJobIds || Object.keys(checkJobIds).length === 0 || !requestData.document_request.applicant_id) return;

    const intervalId = setInterval(async () => {
      if (isUploadingDocument) return;

      const latestApplicationState = await parchaApi.getLatestApplicationState(
        endpoint?.endpointUrl,
        requestData.document_request.applicant_id,
        requestData.document_request.agent_key,
      );

      setLatestApplicationState(latestApplicationState);
    }, 2000);

    setIntervalId(intervalId);

    return () => {
      clearInterval(intervalId);
    };
  }, [checkJobIds, isUploadingDocument, endpoint, requestData]);

  useEffect(() => {
    if (latestApplicationState?.current_check_results?.length > 0) {
      const allCompleted = latestApplicationState?.current_check_results?.every(
        (checkResult) =>
          checkResult.answer !== null && checkResult.passed !== null && (checkResult.follow_up || checkResult.passed),
      );

      if (allCompleted) {
        parchaApi
          .updateRequest(endpoint?.endpointUrl, requestId, {
            state: "review",
          })
          .then(() => clearInterval(intervalId));
      }
    }
  }, [latestApplicationState]);

  if (!requestData) {
    return null;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    await parchaApi.updateApplicationStatus(
      endpoint?.endpointUrl,
      requestData.document_request.applicant_id,
      "submitted",
    );
    setIsApplicationSubmitted(true);
  };

  const incorporationResult = latestApplicationState?.current_check_results?.find(
    (result) => result.command_id === requestData?.document_request.check_id,
  );

  const pastIncorporationResults = latestApplicationState?.past_check_results?.filter(
    (result) => result.command_id === requestData?.document_request.check_id,
  );

  const formatAddress = (address) => {
    const addressLines = [];

    let line1: string = "";
    let line2: string = "";

    if (address.street_1) {
      line1 += address.street_1;
    }

    if (address.street_2) {
      line1 += line1.lenght > 0 ? ` ${address.street_2}` : address.street_2;
    }

    if (address.city) {
      line2 += address.city;
    }

    if (address.state) {
      line2 += line2.length > 0 ? `, ${address.state}` : address.state;
    }

    if (address.country_code) {
      line2 += line2.length > 0 ? ` ${address.country_code}` : address.country_code;
    }

    if (address.postal_code) {
      line2 += line2.length > 0 ? ` ${address.postal_code}` : address.postal_code;
    }

    if (line1.length > 0 && line2.length > 0) {
      return [line1.trim(), line2.trim()];
    }

    return ["Not Provided"];
  };

  const checkId = requestData?.document_request.check_id;

  const getAddressText = (address: any) => {
    const addressText = `${address.street_1 ? address.street_1 : ""} ${address.street_2 ? address.street_2 : ""} ${
      address.city ? address.city : ""
    } ${address.state ? address.state : ""} ${address.zip_code ? address.zip_code : ""}`;
    return addressText.trim();
  };

  const renderFields = () => {
    switch (requestData?.document_request.check_id) {
      case INCORPORATION_DOCUMENT_CHECK_ID:
        return (
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
            <Value
              classNames="md:col-span-3"
              title="Registered Business Name"
              value={requestData.case.registered_business_name || "Not provided"}
            />
            <Value
              title="Registration Number"
              value={requestData.case.business_registration_number || "Not provided"}
            />
            <Value
              classNames="md:col-span-3"
              title="Address of Incorporation"
              value={
                getAddressText(
                  requestData.case.addresses.find((address) => address.address_type === "incorporation"),
                ) || "Not provided"
              }
            />
            <Value title="Date of Incorporation" value={requestData.case.incorporation_date || "Not provided"} />
          </div>
        );
      case EIN_DOCUMENT_CHECK_ID:
        return (
          <div className="grid grid-cols-4 gap-2">
            <Value
              classNames="col-span-2"
              title="Registered Business Name"
              value={requestData.case.registered_business_name || "Not provided"}
            />
            <Value
              classNames="col-span-2"
              title="Employer Identification Number (EIN)"
              value={requestData.case.tin_number || "Not provided"}
            />
          </div>
        );
      case PROOF_OF_ADDRESS_CHECK_ID:
        return (
          <div className="grid grid-cols-4 gap-2">
            <Value
              classNames="col-span-2"
              title="Business Name"
              value={requestData.case.business_name || "Not provided"}
            />
            <Value
              classNames="col-span-2"
              title="Address"
              value={
                getAddressText(requestData.case.addresses.find((address) => address.address_type === "operations")) ||
                "Not provided"
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="grid grid-rows-[60px_1fr] h-screen auto-rows-auto text-xs overflow-auto">
      <div className="flex items-center gap-x-2 px-6 py-3 border-b border-b-slate-200">
        {/* <img src={parchaLogo} alt="Parcha logo" width={100} height={60} /> */}
        <div className="flex items-center justify-center rounded-full bg-brand-purple">
          <OfflineBoltRounded className="text-white" />
        </div>
        <p className="font-semibold text-lg">FastBank</p>
      </div>
      <div className="bg-slate-50 flex py-10 justify-center">
        {isApplicationSubmitted ? (
          <div className="max-w-3xl mx-auto h-fit flex flex-col gap-y-3 mt-5 bg-white p-10 rounded-lg border border-solid border-slate-200">
            <h2 className="text-lg font-semibold text-green-600">Success</h2>
            <div className="text-slate-900 text-sm">
              <p className="font-semibold">Your documents have been validated and submitted.</p>
              <p>We will be in touch after we complete reviewing your application.</p>
            </div>
          </div>
        ) : (
          <div className="w-[95vw] md:w-3/4 md:max-w-3xl h-fit px-12 py-6 flex flex-col gap-y-10 bg-white rounded-lg border border-solid border-slate-200">
            <div className="flex flex-col gap-y-1">
              <span className="font-semibold text-base">{`${
                CHECK_ID_TO_TITLE[requestData?.document_request.check_id]
              } Document Request`}</span>
              <span>Validate and submit your documents securely here.</span>
            </div>
            <div className="flex flex-col md:flex-row gap-5 md:items-center md:justify-between">
              <div className="flex flex-wrap gap-2 items-baseline">
                <span>Status:</span>
                <span
                  className={twJoin(
                    "px-2 py-1 rounded-md text-xs text-nowrap",
                    DOCUMENT_REQUEST_STATUS_TO_CLASSNAMES[requestData.document_request.state],
                  )}
                >
                  {snakeCaseToProperCase(requestData.document_request.state)}
                </span>
              </div>
              {requestData.document_request.updated_at && (
                <div className="flex flex-wrap gap-2 items-center">
                  <span>Updated at:</span>
                  <span>
                    {format(new Date(`${requestData.document_request.updated_at}Z`), "MMMM dd, yyyy hh:mm:ss a")}
                  </span>
                </div>
              )}
            </div>
            {renderFields()}
            <div>
              <DocumentField
                title={`${CHECK_ID_TO_TITLE[requestData?.document_request.check_id]} Document(s)`}
                iconKey={`${CHECK_ID_TO_ICON[requestData?.document_request.check_id]}`}
                fieldKey={requestData?.document_request.check_id}
                description="Please ensure that your all documents are clear, legible and include your name."
                endpoint={endpoint}
                setIsUploadingDocument={setIsUploadingDocument}
                afterFileUploadedCallback={(uploadedFile) => {
                  const filteredCurrentCheckResults = latestApplicationState?.current_check_results?.filter(
                    (result) => result.command_id !== checkId,
                  );
                  const filteredPastCheckResults = latestApplicationState?.past_check_results?.filter(
                    (result) => result.command_id !== checkId,
                  );
                  if (filteredCurrentCheckResults?.length === 0) {
                    setLatestApplicationState({
                      ...latestApplicationState,
                      current_check_results: [...filteredCurrentCheckResults],
                      past_check_results: [...filteredPastCheckResults],
                    });
                  }
                  return runFileCheck(requestData?.document_request.check_id, uploadedFile);
                }}
                afterFileDeletedCallback={() => {}}
                result={incorporationResult}
                pastResults={pastIncorporationResults}
              />
            </div>
            <div className="flex justify-end">
              <button className="bg-brand-purple text-white px-4 py-2 rounded-md" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocumentRequestSubmission;
