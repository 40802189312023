import React from "react";
import { Language, Business, LocationOn, CheckCircle, Cancel, Search } from "@mui/icons-material";
import { snakeCaseToProperCase } from "@/utils";

type WebRelevanceCardProps = {
  payload: {
    reason_for_relevance: string;
    reason_against_relevance: string;
    is_relevant: boolean;
    extracted_metadata: {
      found_business_name: string;
      found_business_country: string | null;
      webpage_type: string;
      visual_summary: string;
      summary: string;
      short_summary: string;
      is_website_accessible: boolean;
      not_accessible_reason: string | null;
      urls_to_analyze: string[];
      search_keywords: string[];
    };
  };
};

const WebRelevanceCard: React.FC<WebRelevanceCardProps> = ({ payload }) => {
  const { extracted_metadata: metadata, is_relevant } = payload;

  if (!metadata) {
    return null;
  }

  return (
    <div className="w-full bg-white border border-slate-200 rounded-lg overflow-hidden mt-2">
      <div className="bg-brand-purple text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <Language className="mr-2" />
          <h2 className="text-xl font-bold">Website Analysis</h2>
        </div>
        {is_relevant ? (
          <span className="bg-green-700 text-white px-3 py-1 rounded-full text-sm font-semibold flex items-center">
            <CheckCircle className="mr-1" fontSize="small" />
            Relevant
          </span>
        ) : (
          <span className="bg-red-500 text-white px-3 py-1 rounded-full text-sm font-semibold flex items-center">
            <Cancel className="mr-1" fontSize="small" />
            Not Relevant
          </span>
        )}
      </div>

      <div className="p-6 space-y-6">
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold mb-2 flex items-center">
              <Business className="mr-2 text-brand-purple" />
              Business Details
            </h3>
            <p>
              <strong>Name:</strong> {metadata.found_business_name}
            </p>
            <p>
              <strong>Country:</strong> {metadata.found_business_country || "Not specified"}
            </p>
            <p>
              <strong>Website Type:</strong> {snakeCaseToProperCase(metadata.webpage_type)}
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Summary</h3>
            <p className="text-sm">{metadata.summary}</p>
          </div>

          {metadata.visual_summary && (
            <div>
              <h3 className="text-lg font-semibold mb-2 flex items-center">
                <Search className="mr-2 text-brand-purple" />
                Visual Summary
              </h3>
              <p className="text-sm">{metadata.visual_summary}</p>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-lg font-semibold mb-2 flex items-center">
                <LocationOn className="mr-2 text-brand-purple" />
                URLs to Analyze
              </h3>
              <ul className="ml-4 list-disc list-inside text-sm">
                {metadata.urls_to_analyze.map((url, index) => (
                  <li key={index} className="truncate">
                    <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">
                      {url}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">Search Keywords</h3>
              <div className="flex flex-wrap gap-2">
                {metadata.search_keywords.map((keyword, index) => (
                  <span key={index} className="bg-slate-200 text-slate-700 px-2 py-1 rounded-full text-xs">
                    {keyword}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4 p-4">
        <h3 className="text-lg font-semibold mb-2">Relevance Analysis</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="bg-slate-100 p-4 rounded-lg">
            <h4 className="font-medium text-green-700">Reasons For Relevance</h4>
            <p className="text-sm">{payload.reason_for_relevance}</p>
          </div>
          <div className="bg-slate-100 p-4 rounded-lg">
            <h4 className="font-medium text-red-700">Reasons Against Relevance</h4>
            <p className="text-sm">{payload.reason_against_relevance || "None"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebRelevanceCard;
