// @ts-nocheck
import { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import COUNTRIES from "@/data/countries";
import InputWithOverlappingLabel from "./InputWithOverlappingLabel";

const AddressInput = ({ address, onAddressChange, onPlaceChange }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceSelectionChange = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      const addressComponents = {};

      // Loop through the address components of the selected place
      place.address_components.forEach((component) => {
        const types = component.types;
        if (types.includes("street_number")) {
          addressComponents.street1 = `${component.long_name} ${addressComponents.street1 || ""}`;
        }
        if (types.includes("route")) {
          addressComponents.street1 = `${addressComponents.street1 || ""} ${component.long_name}`;
        }
        if (types.includes("subpremise")) {
          addressComponents.street2 = component.long_name;
        }
        if (types.includes("locality")) {
          addressComponents.city = component.long_name;
        }
        if (types.includes("administrative_area_level_1")) {
          addressComponents.state = component.short_name;
        }
        if (types.includes("country")) {
          addressComponents.countryCode = component.long_name;
        }
        if (types.includes("postal_code")) {
          addressComponents.postalCode = component.long_name;
        }
      });

      onPlaceChange(addressComponents);
    } else {
      console.log("Autocomplete is not loaded yet!");
    }
  };

  const handleChange = (e) => {
    onAddressChange(e);
  };

  return (
    <div className="w-full grid grid-cols-5 gap-x-3 space-y-4">
      <div className="col-span-full">
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceSelectionChange}>
          <InputWithOverlappingLabel
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            fieldName="street1"
            value={address.street1}
            onChangeHandler={handleChange}
            label="Street 1"
          />
        </Autocomplete>
      </div>
      <div className="col-span-full">
        <InputWithOverlappingLabel
          fieldName="street2"
          value={address.street2}
          onChangeHandler={handleChange}
          label="Street 2"
        />
      </div>
      <div className="col-span-1">
        <InputWithOverlappingLabel fieldName="city" value={address.city} onChangeHandler={handleChange} label="City" />
      </div>
      <div>
        <InputWithOverlappingLabel
          fieldName="state"
          value={address.state}
          onChangeHandler={handleChange}
          label="State"
        />
      </div>
      <div className="col-span-2">
        <div className="relative">
          <label className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-slate-900 w-auto truncate">
            Country Code
          </label>
          <select
            id="countryCode"
            name="countryCode"
            placeholder="Country"
            className="block w-full rounded-md border-0 py-1.5 text-slate-900 shadow-sm ring-1 ring-inset ring-slate-300 placeholder:text-slate-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-6"
            value={address.countryCode}
            onChange={handleChange}
          >
            {COUNTRIES.map((country) => (
              <option key={country.value} value={country.value}>
                {country.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <InputWithOverlappingLabel
          fieldName="postalCode"
          value={address.postalCode}
          onChangeHandler={handleChange}
          label="Postal Code"
        />
      </div>
    </div>
  );
};

export default AddressInput;
