// @ts-nocheck
import { Fragment, useContext, useEffect, useState } from "react";
import { twJoin } from "tailwind-merge";
import { useParchaApi } from "../hooks/useParchaApi";
import { formatRelative } from "date-fns";
import { enUS } from "date-fns/locale";
import { snakeCaseToProperCase } from "@/utils";
import { useParams } from "react-router-dom";
import UserContext from "@/contexts/UserContext";
import { Endpoint } from "@/types";
import { CHECK_ID_TO_TITLE, DOCUMENT_REQUEST_STATUS_TO_CLASSNAMES } from "@/constants/vars";
import ParchaLoadingScreen from "./V2_Home_Page/ParchaLoadingScreen";
import { Dialog, Transition } from "@headlessui/react";
import { BusinessRounded, CloseRounded, PersonRounded, PlagiarismRounded } from "@mui/icons-material";
import DocumentRequestInfo from "./TailwindComponents/DocumentRequestInfo";

const DocumentRequestsListView = () => {
  const parchaApi = useParchaApi();
  const [isLoading, setIsLoading] = useState(true);
  const [documentRequests, setDocumentRequests] = useState([]);
  const [selectedDocumentRequest, setSelectedDocumentRequest] = useState(null);
  const { agentKey } = useParams();
  const userContext = useContext(UserContext);

  const endpoints = userContext?.endpoints;
  const endpoint = endpoints?.find((endpoint: Endpoint) => endpoint.agentKey === agentKey);
  const endpointUrl = endpoint?.endpointUrl;
  const agent = userContext?.agent;

  const formatRelativeLocale = {
    lastWeek: "'Last' eeee 'at' p",
    yesterday: "'Yesterday at' p",
    today: "'Today at' p",
    tomorrow: "'Tomorrow at' p",
    nextWeek: "eeee 'at' p",
    other: "P",
  };

  const locale = {
    ...enUS,
    formatRelative: (token: string) => formatRelativeLocale[token],
  };

  useEffect(() => {
    if (!endpointUrl || !agentKey) return;

    setIsLoading(true);
    parchaApi.getDocumenRequestsList(endpointUrl, agentKey).then((res) => {
      setDocumentRequests(res);
      setIsLoading(false);
    });
  }, [endpointUrl, agentKey]);

  return isLoading ? (
    <ParchaLoadingScreen message="Loading document requests..." size="large" />
  ) : (
    <div className="w-full overflow-y-auto h-[calc(100vh-4.5rem)]">
      <table className="w-full">
        <tbody className="divide-y divide-solid divide-slate-200">
          {documentRequests?.length > 0 ? (
            documentRequests.map((dr) => {
              return (
                <tr key={dr.document_request.id}>
                  <td className="py-5 px-10 text-left">
                    <div className="flex flex-col gap-1">
                      <span className="text-sm font-semibold">
                        {dr.case?.business_name || dr.case?.registered_business_name || "N/A"}
                      </span>
                      <span className="text-slate-500 text-xs">
                        {dr.document_request?.check_id ? CHECK_ID_TO_TITLE[dr.document_request.check_id] : "N/A"}
                      </span>
                    </div>
                  </td>
                  <td className="py-5 px-10 text-right text-xs">
                    {formatRelative(new Date(`${dr.document_request.created_at}Z`), new Date(), { locale })}
                  </td>
                  <td className="py-5 px-10 text-right">
                    <span
                      className={twJoin(
                        "px-2 py-1 rounded-md text-xs text-nowrap",
                        DOCUMENT_REQUEST_STATUS_TO_CLASSNAMES[dr.document_request.state],
                      )}
                    >
                      {snakeCaseToProperCase(dr.document_request.state)}
                    </span>
                  </td>
                  <td>
                    <button
                      onClick={() => setSelectedDocumentRequest(dr)}
                      className="text-brand-purple hover:underline text-xs"
                    >
                      View Request
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="py-5 px-10 text-center italic text-slate-500" colSpan={3}>
                No document requests found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {selectedDocumentRequest && (
        <Transition.Root show={Boolean(selectedDocumentRequest)} as={Fragment} appear>
          <Dialog as="div" className="relative z-20" onClose={() => setSelectedDocumentRequest(null)}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-white bg-opacity-100 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-20 w-full h-full overflow-hidden">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full h-full transition-all">
                  <div className="flex items-center justify-between py-2 px-4 border-b border-solid border-slate-200">
                    <div className="flex items-center justify-between gap-x-2 text-slate-900">
                      <PlagiarismRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                      <h2 className="font-semibold">Document Validation</h2>
                      <div className="flex items-center gap-x-2 ml-5">
                        {agent?.agent_type === "kyb" ? (
                          <BusinessRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        ) : (
                          <PersonRounded sx={{ fontSize: "1rem" }} className="text-slate-500" />
                        )}
                      </div>
                      <span>
                        {selectedDocumentRequest.case.business_name ||
                          selectedDocumentRequest.case.registered_business_name ||
                          ""}
                      </span>
                    </div>
                    <button
                      className="ml-auto inline-flex items-center border border-solid border-slate-200 px-2 py-1 rounded-md text-sm"
                      onClick={() => setSelectedDocumentRequest(null)}
                    >
                      <CloseRounded sx={{ fontSize: "1rem" }} className="mr-1" />
                      <span>Close</span>
                    </button>
                  </div>
                  <DocumentRequestInfo endpoint={endpoint} requestId={selectedDocumentRequest.document_request.id} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </div>
  );
};

export default DocumentRequestsListView;
