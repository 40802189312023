// @ts-nocheck
import { Icon } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import DocumentValue from "./DocumentValue";
import FakeProgress from "fake-progress";
import Progress from "@/components/ui/progress";
import { CancelRounded, CheckCircleRounded, UploadFileRounded } from "@mui/icons-material";
import Alert from "./Alert";
import CheckStatusMessage from "./CheckStatusMessage";
import { useParchaApi } from "@/hooks/useParchaApi";
import { twJoin } from "tailwind-merge";
import { EIN_DOCUMENT_CHECK_ID } from "@/constants/vars";

const DocumentFieldLabel = ({ iconKey, title, description }) => {
  return (
    <div className="flex gap-2.5">
      <Icon sx={{ fontSize: "1.25rem" }} className="material-icons-round text-slate-400">
        {iconKey}
      </Icon>
      <div className="flex flex-col gap-y-2.5">
        {title && <span className="font-semibold text-sm text-slate-700">{title}</span>}
        {description && <span className="text-xs text-slate-600">{description}</span>}
      </div>
    </div>
  );
};

const DocumentUploader = ({
  endpoint,
  afterFileUploadedCallback,
  setIsUploadingDocument,
  result,
}: {
  endpoint: { endpointUrl: string };
  afterFileUploadedCallback: (file: any) => Promise<any>;
  afterFileDeletedCallback: (file: any) => Promise<any>;
  setIsUploadingDocument: (isUploading: boolean) => void;
  result: any;
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isCheckRunning, setIsCheckRunning] = useState(false);
  const [isUploadingStatus, setIsUploadingStatus] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const parchaApi = useParchaApi();
  const inputRef = useRef<HTMLInputElement>(null);

  const [progress, setProgress] = useState(0);

  // const commandResultMessage = statusMessages && statusMessages.find((msg) => msg.event === "command_results");
  const didPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

  useEffect(() => {
    if (!isUploadingStatus && !isCheckRunning) return;

    const p = new FakeProgress({
      timeConstant: 45000,
      autoStart: true,
    });

    const interval = setInterval(() => {
      setProgress(p.progress);
    }, 500);

    return () => clearInterval(interval);
  }, [isCheckRunning, isUploadingStatus]);

  useEffect(() => {
    if (result && result.answer) {
      setIsCheckRunning(false);
    } else if (result) {
      setIsCheckRunning(true);
    } else {
      setIsCheckRunning(false);
    }
  }, [result]);

  const handleDocumentInputClick = () => {
    inputRef.current?.click();
  };
  const uploadFiles = async (files) => {
    if (!files) return;

    setIsUploadingDocument(true);
    setIsUploadingStatus(true);

    const filePromises = Array.from(files).map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (loadEvent: Event) => {
          const base64String = loadEvent?.target?.result?.split(",")[1];
          parchaApi.uploadBase64Document(endpoint.endpointUrl, base64String, file.name).then((result) => {
            const newFile = { ...result, file_name: file.name, source_type: "file_url" };
            delete newFile.expiration;
            resolve(newFile);
          });
        };
        reader.readAsDataURL(file);
      });
    });
    const allFilesUploaded = await Promise.all(filePromises);

    // For now, it is only one file being uploaded
    allFilesUploaded.forEach(async (file) => {
      afterFileUploadedCallback(file).then(() => {
        setSelectedFile(file);
        setIsCheckRunning(true);
        setIsUploadingStatus(false);
        setIsUploadingDocument(false);
      });
    });
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (!files || !files.length) return;

    setSelectedFile(null);
    return uploadFiles(files);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const files = e.dataTransfer.files;
    if (!files) return;

    setSelectedFile(null);

    return uploadFiles(files);
  };

  const statusEvents = result?.status_messages.filter((message) => message.event === "status");
  const lastStatusMessage = statusEvents && statusEvents[statusEvents.length - 1];

  return (
    <div className="flex flex-col gap-2">
      {selectedFile && isCheckRunning ? (
        <>
          <div className="flex items-center gap-4">
            <div className="w-full flex flex-col p-5 border border-slate-300 rounded-lg grow">
              <div className="flex items-center justify-between">
                <DocumentValue fileName={selectedFile.file_name} url={selectedFile.url} maxWidth={1000} />

                {(isUploadingStatus || isCheckRunning) && (
                  <Progress className="w-[5rem] h-[0.5rem]" value={progress * 100} />
                )}
                {!isCheckRunning ? (
                  didPass ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )
                ) : null}
              </div>
              {isCheckRunning && lastStatusMessage && lastStatusMessage.content && (
                <CheckStatusMessage
                  className="px-0 mx-0 mt-2"
                  messageContents={{ status: lastStatusMessage.content.status }}
                />
              )}
              {!isUploadingStatus && !isCheckRunning && result && result?.passed !== null ? (
                <Alert
                  level={!didPass ? "failure" : "success"}
                  message={
                    <div className="flex flex-col gap-y-2">
                      <span>{result.answer}</span>
                      <span className="font-bold">{result.follow_up}</span>
                    </div>
                  }
                />
              ) : null}
            </div>
            {/* <button
                onClick={() => {
                  setSelectedFile(null);
                  afterFileDeletedCallback(selectedFile);
                }}
              >
                <DeleteForeverRounded sx={{ fontSize: "1rem" }} className="text-red-600" />
              </button> */}
          </div>
        </>
      ) : null}
      <div
        className={twJoin(
          "flex flex-col items-center gap-y-1 justify-center h-full text-xs border-2 border-dashed py-6 px-3 rounded-lg",
          isDraggingOver ? "border-blue-500 bg-blue-50" : "border-slate-300",
        )}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragExit={() => setIsDraggingOver(false)}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <UploadFileRounded sx={{ fontSize: "1.25rem" }} className="text-slate-400" />
        <p className="text-slate-700">
          Drag & Drop or{" "}
          <span className="underline text-brand-purple" role="button" onClick={handleDocumentInputClick}>
            Choose a document
          </span>{" "}
          to upload
        </p>
        <input
          type="file"
          accept="image/jpg,image/jpeg,image/png,application/pdf"
          onChange={handleFileSelect}
          onBlur={(e) => e.preventDefault()}
          ref={inputRef}
          className="hidden"
        />
      </div>
    </div>
  );
};

const DocumentField = ({
  iconKey,
  title,
  description,
  endpoint,
  setIsUploadingDocument,
  afterFileUploadedCallback,
  afterFileDeletedCallback,
  result,
  pastResults,
}) => {
  const currentResultDocument =
    result?.command_id === "kyb.proof_of_address_verification"
      ? result?.verification_data?.proof_of_address_documents?.[0]?.document ||
        result?.verification_data?.valid_documents?.[0]?.document
      : result?.command_id === "kyb.incorporation_document_verification"
        ? result?.passed
          ? result?.verification_data?.documented_incorporation_information?.[0]?.document ||
            result?.verification_data?.valid_documents?.[0]?.document
          : result?.verification_data?.invalid_documents?.[0]?.document
        : result?.command_id === "kyb.business_ownership_verification_tool"
          ? result?.passed
            ? result?.verification_data?.documented_ownership[0]?.document ||
              result?.verification_data?.valid_documents?.[0]?.document
            : result?.verification_data?.invalid_documents?.[0]?.document
          : result?.command_id === EIN_DOCUMENT_CHECK_ID
            ? result?.passed
              ? result?.verification_data?.valid_ein_documents?.[0]?.document ||
                result?.verification_data?.valid_documents?.[0]?.document
              : result?.verification_data?.invalid_documents?.[0]?.document
            : null;

  const didCurrentResultPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());

  return (
    <div className="flex flex-col gap-2.5">
      <DocumentFieldLabel iconKey={iconKey} title={title} description={description} />
      {pastResults && pastResults.length > 0 && (
        <div className="flex flex-col gap-y-2">
          {pastResults.map((result) => {
            if (!result.answer) return null;

            const didPass = ["true", "pass", "passed", "yes"].includes(result?.passed?.toString().toLowerCase());
            const document =
              result?.command_id === "kyb.proof_of_address_verification"
                ? result?.verification_data?.proof_of_address_documents?.[0]?.document ||
                  result?.verification_data?.valid_documents?.[0]?.document
                : result?.command_id === "kyb.incorporation_document_verification"
                  ? result?.passed
                    ? result?.verification_data?.documented_incorporation_information?.[0]?.document ||
                      result?.verification_data?.valid_documents?.[0]?.document
                    : result?.verification_data?.invalid_documents?.[0]?.document
                  : result?.command_id === "kyb.business_ownership_verification_tool"
                    ? result?.passed
                      ? result?.verification_data?.documented_ownership[0]?.document ||
                        result?.verification_data?.valid_documents?.[0]?.document
                      : result?.verification_data?.invalid_documents?.[0]?.document
                    : result?.command_id === EIN_DOCUMENT_CHECK_ID
                      ? result?.passed
                        ? result?.verification_data?.valid_ein_documents[0]?.document ||
                          result?.verification_data?.valid_documents?.[0]?.document
                        : result?.verification_data?.invalid_documents?.[0]?.document
                      : null;

            return (
              <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3" key={result.id}>
                <div className="flex justify-between">
                  <DocumentValue fileName={document?.file_name ?? ""} url={document?.url} maxWidth={1000} />
                  {result.passed ? (
                    <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
                  ) : (
                    <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
                  )}
                </div>
                {result.passed !== null && (
                  <Alert
                    level={didPass ? "success" : "failure"}
                    message={
                      <div className="flex flex-col gap-y-2">
                        <span>{result.answer}</span>
                        <span className="font-bold">{result.follow_up}</span>
                      </div>
                    }
                  />
                )}
              </div>
            );
          })}
        </div>
      )}
      {result?.answer && (
        <div className="border border-slate-300 rounded-lg p-5 flex flex-col gap-y-3">
          {currentResultDocument && (
            <div className="flex justify-between">
              <DocumentValue
                fileName={currentResultDocument?.file_name}
                url={currentResultDocument?.url}
                maxWidth={1000}
              />
              {result.passed ? (
                <CheckCircleRounded sx={{ fontSize: "1.25rem" }} className="text-green-600" />
              ) : (
                <CancelRounded sx={{ fontSize: "1.25rem" }} className="text-red-500" />
              )}
            </div>
          )}

          {result.passed !== "null" && (
            <Alert
              level={didCurrentResultPass ? "success" : "failure"}
              message={
                <div className="flex flex-col gap-y-2">
                  <span>{result.answer}</span>
                  <span className="font-bold">{result.follow_up}</span>
                </div>
              }
            />
          )}
        </div>
      )}
      <DocumentUploader
        endpoint={endpoint}
        setIsUploadingDocument={setIsUploadingDocument}
        afterFileUploadedCallback={afterFileUploadedCallback}
        afterFileDeletedCallback={afterFileDeletedCallback}
        result={result}
      />
    </div>
  );
};

export default DocumentField;
